frappe.provide('ngo');

function convertToArabicNumbers(str) {
    const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return str.replace(/\d/g, (d) => arabicNumbers[d]);
}

function convertReportNumbersToArabic() {
    // Convert ids column if exists
    document
        .querySelectorAll("div.dt-cell__content--col-0")
        .forEach((div) => {
            const newValue = convertToArabicNumbers(div.innerText)
            if (div.innerText !== newValue) {
                div.innerText = newValue
            }
        });

    // Convert data cells
    document
        .querySelectorAll(".dt-cell__content")
        .forEach((div) => {
            const child = div.querySelector("div");
            if (child) {
                const newValue = convertToArabicNumbers(child.innerText)
                if (child.innerText !== newValue) {
                    child.innerText = newValue
                }
            }
        });
}

// Handle direct URL access
$(document).ready(function () {
    if (frappe.boot.user.language === 'ar' && window.location.pathname.toLowerCase().includes('report')) {
        // Watch for dynamic updates
        const observer = new MutationObserver(function (mutations) {
            // Check if the body's data-route attribute indicates a report page.
            const root = document.querySelector("#page-query-report")
            if (root) {
                mutations.forEach(function (mutation) {
                    if (mutation.addedNodes.length) {
                        convertReportNumbersToArabic()
                        observer.disconnect()
                        observer.observe(document.body, {
                            childList: true,
                            subtree: true
                        });
                    }
                });
            }
        });
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }
});
