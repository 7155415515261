frappe.ui.Filter = class Filter extends frappe.ui.Filter {
    constructor(opts) {
        super(opts)
        this.exclude_custom_filter_options()
    }

    exclude_custom_filter_options() {
        const excludedFilterOptions = {
            Data: [">", "<", ">=", "<="],
        }

        const DataTypeVariations = [
            "Text",
            "Small Text",
            "Text Editor",
            "Code",
            "Attach",
            "Attach Image",
            "Markdown Editor",
            "HTML Editor",
            "Tag",
            "Phone",
            "JSON",
            "Comments",
            "Barcode",
            "Dynamic Link",
            "Read Only",
            "Assign",
            "Color",
        ]
        if ('Data' in excludedFilterOptions) {
            DataTypeVariations.forEach((fieldtype) => {
                excludedFilterOptions[fieldtype] = excludedFilterOptions['Data']
            })
        }

        Object.keys(excludedFilterOptions).forEach((k) => {
            if (k in this.invalid_condition_map) {
                this.invalid_condition_map[k].push(...excludedFilterOptions[k])
            } else {
                this.invalid_condition_map[k] = DataTypeVariations.includes(k) ? this.invalid_condition_map['Data'] : excludedFilterOptions[k]
            }
        })
    }
}
